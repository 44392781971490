import React from 'react'

function Skills() {
    return (
        <section className="grey">
           <h2 className="mt-0">WHAT I DO</h2>
           <div className="row">
               <div className="third">
                   <h3 className="mb-0">Wordpress</h3>
                   <p className="mt-0">Theme &amp; plugin<br/>development</p>
               </div>
               <div className="third">
                   <h3 className="mb-0">Shopify</h3>
                   <p className="mt-0">Theme development &amp;<br/>customization</p>
               </div>
               <div className="third">
                   <h3 className="mb-0">React</h3>
                   <p className="mt-0">Interactive websites</p>
               </div>
               <div className="third">
                   <h3 className="mb-0">UX/UI</h3>
                   <p className="mt-0">Human centered<br/>design</p>
               </div>
           </div> 
        </section>
    )
}

export default Skills
