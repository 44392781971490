import React from 'react'

function Arrow(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="37" viewBox="0 0 9 37" className={"arrow " + props.direction}>
            <g id="Group_3" data-name="Group 3" transform="translate(-33.5 -275)">
                <path id="Line_4" data-name="Line 4" d="M0,36.5A.5.5,0,0,1-.5,36V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V36A.5.5,0,0,1,0,36.5Z" transform="translate(38 275.5)" fill="#388ac0"/>
                <path id="Line_5" data-name="Line 5" d="M0,5.5a.5.5,0,0,1-.312-.11.5.5,0,0,1-.078-.7l4-5a.5.5,0,0,1,.7-.078.5.5,0,0,1,.078.7l-4,5A.5.5,0,0,1,0,5.5Z" transform="translate(38 306.5)" fill="#388ac0"/>
                <path id="Line_6" data-name="Line 6" d="M4,5.5a.5.5,0,0,1-.391-.188l-4-5a.5.5,0,0,1,.078-.7.5.5,0,0,1,.7.078l4,5A.5.5,0,0,1,4,5.5Z" transform="translate(34 306.5)" fill="#388ac0"/>
            </g>
        </svg>
    )
}

export default Arrow
