import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useStaticQuery, graphql, Link } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet';
import Arrow from '../components/Arrow';
import Layout from '../components/Layout'

import Skills from '../components/Skills'

import * as projectStyles from '../styles/project.module.scss'

function Projects() {
    const projects = useStaticQuery(graphql`
        query{
            allContentfulProject{
                edges{
                    node{
                        slug
                        title
                        description{
                            raw
                        }
                        screenshot{
                            file{
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    return (
        <>
            <Helmet title="Projects | MM-develops" />
            <Layout class="blue">
                <section className="blue">
                    <h1>PROJECTS</h1>
                    <p>I work as a part-time developer at Branding pool, and freelance in my free time. I can't show all of the websites I've developed at my part-time so here is a selection of my freelancer projects.</p>
                </section>
                <ul className={ projectStyles.projects }>

                    { projects.allContentfulProject.edges.map( (project)=>{
                        
                        const screenshotData = project.node.screenshot.file.url;
                        let screenshot;
                        if( screenshotData !== ''){
                            screenshot = <img src={ screenshotData } alt="Project Screenshot"/>
                        }
                        
                        //console.log(project.node.description.raw)
                        return(
                            <li key={project.node.slug} className="row">
                                <div className={ "half " + projectStyles.description }>
                                    <h2>{ project.node.title }</h2>
                                    { documentToReactComponents(JSON.parse(project.node.description.raw)) }
                                    <Link to={ '/projects/' + project.node.slug } className={ projectStyles.link}>Read more <Arrow direction="right"/></Link>
                                </div>
                                <div className="half">
                                    <Link to={ '/projects/' + project.node.slug }>{ screenshot }</Link>
                                </div>

                            </li>
                        )
                    }) }
                </ul>
                <Skills/>
            </Layout>
        </>
    )
}

export default Projects
